import { useNavigate, useSearchParams } from "@solidjs/router";
import { onCleanup, onMount, Show } from "solid-js";
import { HttpStatusCode } from "@solidjs/start";
import ContentLayout from "~/layouts/ContentLayout";
import BackButton from "~/components/shared/BackButton";
import { useI18n } from "@solid-primitives/i18n";

export default function ProxyPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [t] = useI18n();
  const url = searchParams.url;
  let timeoutId: number | undefined;

  onMount(() => {
    if (!url) {
      navigate("/", { replace: true });
    } else {
      // Wait for 2.5 seconds before redirecting
      timeoutId = setTimeout(() => {
        window.location.href = decodeURIComponent(url);
      }, 2500);
    }
  });

  onCleanup(() => {
    clearTimeout(timeoutId);
  });

  const decodedUrl = decodeURIComponent(url || "");

  return (
    <Show when={url}>
      <HttpStatusCode code={307} />
      <ContentLayout as="content">
        <div class="flex flex-col justify-center items-center h-svh m-auto">
          <div class="jl-centered m-auto">
            <h1 class="text-3xl font-bold mb-4">{t("courtesy_notice")}</h1>
            <div class="loader mb-4 mx-auto" />
            <section class="text-center">
              <p class="ff-sans-serif font-medium text-xl mb-4">
                Just letting you know, you're being redirected to:
              </p>
              <p class="ff-mono text-lg text-neutral-50">{decodedUrl}</p>
              <div class="mt-16 border-t border-neutral-400/10 border-solid pt-8 sm:mt-20 lg:mt-24">
                <p class="ff-sans-serif font-medium text-xl mb-4">
                  If you don't want to be redirected, you can go back to the
                  previous page.
                </p>
                <BackButton />
              </div>
            </section>
          </div>
        </div>
      </ContentLayout>
    </Show>
  );
}
